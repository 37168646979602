// myApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../static/config';

const myApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrl,
  }),
  tagTypes: ['Orders'],
  endpoints: (builder) => ({
    getOrdersByUserId: builder.query({
      query: (userId) => `/orders/${userId}`,
      providesTags: ['Orders'],
    }),
    addOrder: builder.mutation({
      query: (newOrder) => ({
        url: '/orderRequests',
        method: 'POST',
        body: newOrder,
      }),
      invalidatesTags: ['Orders'],
   
    }),
  }),
});

export const { useGetOrdersByUserIdQuery, useAddOrderMutation } = myApi;

export default myApi;
