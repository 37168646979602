import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams and useHistory hooks
import axios from 'axios';

const ApproveOrder = () => {
    const { id } = useParams(); // Get the order ID from the URL
    const history = useNavigate(); // Get the history object for programmatic navigation

    useEffect(() => {
        const approveOrder = async () => {
            try {
                // Make an API request to approve the order
                await axios.put(`/orders/${id}/approve`);
                console.log('Order approved successfully');
                // Redirect to the home page
                history('/');
            } catch (error) {
                console.error('Error approving order:', error);
                // Handle the error if needed
            }
        };

        approveOrder();

        // Clean up function
        // return () => {
        //     // Optionally, you can cancel any ongoing requests or perform cleanup tasks
        // };
    }, [id, history]); // Include orderId and history in the dependency array

    return (
        <div>
            approving......
            {/* Optionally, you can render any content here */}
        </div>
    );
};

export default ApproveOrder;
