import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Navbar from './features/Header';
import Footer from './features/Footer';
import LoginPage from './features/Login';
import OrderStatusPage from './features/MyOrders';
import ProductListPage from './features/ProductList';
import OrdersPage from './features/Main';
import AddItemPage from './features/Admin';
import ApproveOrder from './features/ApproveOrder';
import axios from './axios'
// Loader component
const Loader = () => (
  <div className="loader">
    Loading...
  </div>
);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    setIsLoggedIn(!!userId); // Check if userId exists in localStorage
    setLoading(false); // Set loading to false after component mounts

    if (!userId) {
      navigate('/');
    }
  }, [navigate]);

  return (
   
      <div className="App">
        <header className="App-header">
          <Navbar onLogout={() => setIsLoggedIn(false)} />
          <div style={{ minHeight: '80vh' }}>
            {loading ? ( // Render loader if loading state is true
              <Loader />
            ) : (
              <Routes>
                <Route path="/approve/:id" element={<ApproveOrder />} />
                {!isLoggedIn && <Route path="/" element={<LoginPage onLogin={() => setIsLoggedIn(true)} />} />}
                {isLoggedIn && (
                  <>
                    <Route path="/" element={<OrdersPage />} />
                    <Route path="/products" element={<ProductListPage />} />
                    <Route path="/myorders" element={<OrderStatusPage />} />
                    <Route path="/admin" element={<AddItemPage />} />
                  </>
                )}
              </Routes>
            )}
          </div>
          <Footer />
        </header>
      </div>
 
  );
}

export default App;
