import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import { useAddOrderMutation } from '../app/redux';

const ProductListPage = () => {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [selectedLocation, setSelectedLocation] = useState('');
  const [orderPlaced, setOrderPlaced] = useState(false);
  const navigate = useNavigate();
  const [addOrder] = useAddOrderMutation();
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/getproducts');
        setProducts(response.data);
        setSelectedLocation(localStorage.getItem('location') || "")
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleGoBack = () => {
    navigate('/');
  };

  const handleAddToCart = (product) => {
    const quantity = quantities[product._id] || 1;
    const existingCartItemIndex = cart.findIndex(item => item._id === product._id);
    if (existingCartItemIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingCartItemIndex].quantity = quantity;
      setCart(updatedCart);
    } else {
      const newItem = { ...product, quantity, location: selectedLocation };
      setCart([...cart, newItem]);
    }
    setQuantities({ ...quantities, [product._id]: quantity });
  };

  const handleQuantityChange = (productId, quantity) => {
    setQuantities({ ...quantities, [productId]: quantity });
  };

  const handleRemoveFromCart = (productId) => {
    const updatedCart = cart.filter(item => item._id !== productId);
    setCart(updatedCart);
    const updatedQuantities = { ...quantities };
    delete updatedQuantities[productId];
    setQuantities(updatedQuantities);
  };

  const calculateItemTotal = (product) => {
    const quantity = quantities[product._id] || 1;
    return (product.price * quantity).toFixed(2);
  };

  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => {
      return total + (item.price * item.quantity);
    }, 0).toFixed(2);
  };

  const handleSubmit = async () => {
    try {
       await axios.post('/orderRequests', { location: selectedLocation, userId: localStorage.getItem('userId'), cart });
    //  const resp =  await addOrder({ location: selectedLocation, userId: localStorage.getItem('userId'), cart });
      setCart([]);
      setOrderPlaced(true);
      Swal.fire({
        icon: 'success',
        title: 'Request Placed!',
        text: 'Order request created successfully',
      });
    } catch (error) {
      console.error('Error submitting cart:', error);
    }
  };

  return (
    <div className="product-list-page">
      <h1>Product List</h1>
      <div className="location-input">
        <label>Enter Location:</label>
        <input
          type="text"
          value={selectedLocation}
          onChange={(e) => setSelectedLocation(e.target.value)}
          placeholder="Enter your location"
        />
      </div>
      <div className="products-table">
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Carton Size</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {products.map(product => (
              <tr key={product._id}>
                <td>
                  <img src={`https://drive.google.com/thumbnail?id=${product.image}`} alt={product.name} style={{ width: '50px', height: '50px' }} />
                </td>
                <td>{product.name}</td>
                <td>₹{product?.price?.toFixed(2)}</td>
                <td>
                  <input
                    type="number"
                    min="0"
                    value={quantities[product._id] || ''}
                    onChange={(e) => handleQuantityChange(product._id, parseInt(e.target.value))}
                  />
                </td>
                <td>{product.size || 0}</td>
                <td>
                  <button onClick={() => handleAddToCart(product)}>Add</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      <div className="shopping-cart-container">
        <h2>Shopping Cart</h2>
        <ul>
          {cart.map(item => (
            <li key={item._id} className="cart-item">
              <span className="cart-item-name">{item.name}</span>
              <span className="cart-item-quantity">Quantity: {item.quantity}</span>
              <span className="cart-item-price">Price: ₹{(item.price * item.quantity).toFixed(2)}</span>
              <span className="cart-item-total">Total: ₹{calculateItemTotal(item)}</span>
              <span className="cart-item-remove" onClick={() => handleRemoveFromCart(item._id)}>Remove</span>
            </li>
          ))}
        </ul>
        <div className="cart-total">Total: ₹{calculateTotalPrice()}</div>
        <button className="submit-button" onClick={handleSubmit}>Submit</button>
        <div className="back-button-container">
          <button className="back-button" onClick={handleGoBack}>Back</button>
        </div>
      </div>
      {orderPlaced && (
        <div className="order-placed-popup">
          <p>Order has been placed!</p>
          <button onClick={() => setOrderPlaced(false)}>Close</button>
        </div>
      )}
    </div>
  );
}

export default ProductListPage;
