import React from 'react';
import { Link } from 'react-router-dom';

const OrdersPage = () => {
    return (
        <div>
            <div className="container" style={{ flexDirection: "column" }}>
                <div>
                    <h1>Welcome User</h1>
                </div>
                <div className="navigation-widgets">
                    <div className="navigation-widget">
                        <h2>My Orders</h2>
                        <p>View past orders.</p>
                        <Link to="/myorders">My Orders</Link>
                    </div>
                    <div className="navigation-widget">
                        <h2>Place Order</h2>
                        <p>Place a new order.</p>
                        <Link to="/products">Place Order</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrdersPage;
