import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminPage = () => {
  const [type, setType] = useState('product'); // Default to adding product
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false); // Default to not admin
  const [items, setItems] = useState([]); // State to store fetched items
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchItems(); // Fetch items when component mounts
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get(`/get${type}s`); // Fetch users or products based on selected type
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const handleAddItem = async (e) => {
    e.preventDefault();
    try {
      if (type === 'product') {
        // Add product
        const response = await axios.post('/addProduct', { name, price });
        setMessage('Product added successfully');
      } else {
        // Add user
        const response = await axios.post('/addUser', { username, password, isAdmin: isAdmin ? 1 : 0 });
        setMessage('User added successfully');
      }
      // Reset fields
      setName('');
      setPrice('');
      setUsername('');
      setPassword('');
      setIsAdmin(false);
      fetchItems(); // Fetch updated list of items
    } catch (error) {
      setMessage('Failed to add item');
      console.error('Error adding item:', error);
    }
  };

  const handleDeleteItem = async (itemId) => {
    try {
      if (type === 'product') {
        // Delete product
        await axios.delete(`/removeProduct/${itemId}`);
      } else {
        // Delete user
        await axios.delete(`/removeUser/${itemId}`);
      }
      setMessage('Item removed successfully');
      fetchItems(); // Fetch updated list of items
    } catch (error) {
      setMessage('Failed to remove item');
      console.error('Error removing item:', error);
    }
  };

  const handleGoBack = () => {
    navigate('/orders');
  };

  return (
    <div className="admin-container">
      <h1 className="admin-heading">Admin Dashboard</h1>
      <form onSubmit={handleAddItem} className="admin-form">
        <label className="admin-label">
          Type:
          <select value={type} onChange={(e) => setType(e.target.value)} className="admin-select">
            <option value="product">Product</option>
            <option value="user">User</option>
          </select>
        </label>
        {type === 'product' ? (
          <>
            <label className="admin-label">
              Product Name:
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="admin-input" />
            </label>
            <label className="admin-label">
              Price:
              <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} className="admin-input" />
            </label>
          </>
        ) : (
          <>
            <label className="admin-label">
              Username:
              <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} className="admin-input" />
            </label>
            <label className="admin-label">
              Password:
              <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="admin-input" />
            </label>
            <label className="admin-label">
              Is Admin:
              <input type="checkbox" checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)} />
            </label>
          </>
        )}
        <button type="submit" className="admin-button">Add Item</button>
      </form>
      <button onClick={handleGoBack} className="admin-button">Back</button>
      {message && <p className="admin-message">{message}</p>}
      
      {/* Display list of items */}
      <ul>
        {items.map(item => (
          <li key={item._id}>
            {item.name} - {item.price || (item.isAdmin ? 'Admin' : 'User')}
            <button onClick={() => handleDeleteItem(item._id)}>Delete</button>
            {/* Add option to update item */}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AdminPage;
