import React from 'react';
import Logo from '../logo.png'; // Import your company logo image
import { Link } from 'react-router-dom';

const Navbar = ({onLogout}) => {
  const isAdmin = localStorage.getItem('isAdmin') === '1';
  const isLoggedIn = localStorage.getItem('userId');

  const handleLogout = () => {
    // Clear localStorage
    localStorage.clear();
    onLogout()
    // Redirect to login page or perform any other actions
  };

  return (
    <nav className="navbar">
      <div className="container">
        <Link to="/" className="logo">
          <img src={Logo} alt="Company Logo" />
        </Link>
        <ul className="nav-links">
          {isAdmin && <li><Link to="/admin" className="nav-link">Admin</Link></li>}
          {isLoggedIn && <li><button onClick={handleLogout} className="nav-button">Logout</button></li>}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
