import React, { useState } from 'react';
import axios from 'axios';

// const api = axios.create({
//   baseURL: 'http://localhost:3000',
// });

function LoginPage({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/login', { username, password });
      // If login is successful, set user ID in local storage
      localStorage.setItem('userId', response.data.id);
      localStorage.setItem('location', response.data.location);
      if(response.data.isAdmin == 1){
        localStorage.setItem('isAdmin', 1);

      }
      // Invoke the onLogin callback passed from parent component
      onLogin();
    } catch (error) {
      setError(error?.response?.data?.error || 'Invalid username or password');
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default LoginPage;
