import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useGetOrdersByUserIdQuery } from '../app/redux';



const OrderStatusPage = () => {
    // const [orders, setOrders] = useState([]);
    const userId = localStorage.getItem('userId');
    const navigate = useNavigate();
    console.log('check',useGetOrdersByUserIdQuery)
    const { data: orders, isLoading, isError } = useGetOrdersByUserIdQuery(userId);

   console.log(orders, isLoading, isError,'etst')
    // useEffect(() => {
    //     const fetchOrders = async () => {
    //         try {
    //             const response = await axios.get(`/orders/${userId}`);
    //             setOrders(response.data);
    //         } catch (error) {
    //             console.error('Error fetching orders:', error);
    //         }
    //     };

    //     fetchOrders();
    // }, [userId]); // Fetch orders when the component mounts and whenever userId changes

    const getOrderStatusLabel = (status) => {
        switch (status) {
            case '1':
                return 'Order Received';
            case '2':
                return 'Invoice Created';
            case '3':
                return 'Order Dispatched';
            case '4':
                return 'In Transit';
            case '5':
                return 'Out for Delivery';
            case '6':
                return 'Delivered';
            default:
                return '';
        }
    };

    const getPaymentStatusLabel = (status) => {
        switch (status) {
            case '1':
                return 'Payment Pending';
            case '2':
                return 'Invoice Submitted to Accounts';
            case '3':
                return 'Invoice Processed';
            case '4':
                return 'Paid';
            default:
                return '';
        }
    };
    
    const handleGoBack = () => {
        navigate('/');
    };
    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error fetching orders</div>;
    return (
        <div className="order-status-page">
            <h1>Order Status</h1>
            {orders.length === 0 ? (
                <p>No orders found for this user.</p>
            ) : (
                <div className="progress-container">
                    {orders.map(order => (
                        <div key={order._id} className="order-progress">
                            {/* <h2>Location: {order.location}</h2> */}
                            <h3>Invoice Number: {order.inv_no || order._id }</h3>
                            <div className="status-container">
                                <div className="order-status">
                                    {['1', '2', '3', '4', '5', '6'].map(step => (
                                        <div key={step} className={`step ${order.status === step ? 'active' : ''}`}>
                                            {getOrderStatusLabel(step)}
                                        </div>
                                    ))}
                                </div>
                                <div className="payment-status">
                                    {['1', '2', '3', '4'].map(step => (
                                        <div key={step} className={`step ${order.paymentStatus === step ? 'active' : ''}`}>
                                            {getPaymentStatusLabel(step)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className="back-button-container">
                <button className="back-button" onClick={handleGoBack}>Back</button>
            </div>
        </div>
    );
}

export default OrderStatusPage;
