import React from 'react';
import Logo from '../logo.png'; // Import your company logo image

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={Logo} alt="Company Logo" />
          </div>
        
        </div>
        <div className="footer-text">
          <p>&copy; {new Date().getFullYear()} Your Company. All rights reserved.</p>
          {/* Add any additional footer text here */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
